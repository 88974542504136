import { Injectable, EventEmitter, Output, HostListener, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Router } from "@angular/router";
import { PackageModel } from '../models/PackageModel';
import { DocumentModel } from '../models/DocumentModel';
import { StatusModel } from '../models/StatusModel';
import { LazyLoadEvent } from 'primeng/api';
import { PackageModelLazy } from '../models/PackageModelLazy';
import { DocumentModelLazy } from '../models/DocumentModelLazy';


@Injectable()
export class PackageService {

  url: string;
  //public PackageApiServerLink: string;

  constructor(
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }


  getPackagesCount(partnerId: string) {
    return this.http.get<number>(this.url + 'api/package/getPackagesCount/' + partnerId);
  }

  getPackagesLazy(filter: any) {

    return this.http.post<PackageModelLazy>(this.url + 'api/package/GetPackagesLazy/', filter);
  }

  getDocumentsByIdPackage(filter: any) {
    return this.http.post<DocumentModelLazy>(this.url + 'api/package/doc/', filter);
  }

  getPackageStatus() {
    return this.http.get<StatusModel[]>(this.url + 'api/package/GetPackagesStatus/');
  }

  getDocumentStatus() {
    return this.http.get<StatusModel[]>(this.url + 'api/package/GetDocumentsStatus/');
  }

  getRefreshInSecond() {
    return this.http.get<number>(this.url + 'api/package/GetRefreshInSecond/');
  }

  updatePackageStatus(selectedPackage: PackageModel) {
    return this.http.post<any>(this.url + 'api/package/UpdatePackageStatus/', selectedPackage);
  }

  updateDocumentStatus(selectedDocument: DocumentModel) {
    return this.http.post<any>(this.url + 'api/package/UpdateDocumentStatus/', selectedDocument);
  }

  getXmlReceived(packageId: string) {
    return this.http.get<string>(this.url + 'api/package/getXmlReceived/' + packageId);
  }

  getXmlSent(packageId: string) {
    return this.http.get<string>(this.url + 'api/package/getXmlSent/' + packageId);
  }

  getOkoroInd(packageId: string) {
    return this.http.get<string>(this.url + 'api/package/getOkoroInd/' + packageId);
  }

  getMailevaZip(documentId: string) {
    return this.http.get<string>(this.url + 'api/package/getMailevaZip/' + documentId);
  }
}
