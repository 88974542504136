import { MessageService, DialogService, MenuItem, ConfirmationService } from 'primeng/api';
import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/Services/user.service';
import { SenderModel } from 'src/app/models/SenderModel';
import { PartnerModel } from 'src/app/models/PartnerModel';


@Component({
  templateUrl: './senderManagement.component.html',
  styleUrls: ['./senderManagement.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, MessageService, ConfirmationService]
})
export class senderManagement {

  loading = true;
  displayDialog: boolean;
  columns: any[];
  senderCollection: any[];
  sender: SenderModel;
  selectedSender: SenderModel;
  newSender: boolean;
  allPartners: any[];
  partnersFilter: any[];

  constructor(private userService: UserService, private messageService: MessageService, private confirmationService: ConfirmationService) {

  }

  ngOnInit() {

    this.columns = [
      { field: 'code', header: 'Code' },
      { field: 'label', header: 'Label' },
      { field: 'partnerLabel', header: 'Partenaire' }
    ];

    this.userService.getAllProdAutoPartners().subscribe(partners => {
      this.allPartners = partners;

      this.allPartners = this.allPartners.map(x => (
        {
          value: x.id,
          label: x.label
        }));
      this.allPartners.unshift({
        value: null,
        label: null
      });

      this.partnersFilter = partners.map(x => (
        {
          value: x.label,
          label: x.label
        }));
      this.partnersFilter.unshift({
        value: null,
        label: null
      });
    });

    this.userService.getAllProdAutoSenders().subscribe(senders => {
      this.loading = false;
      this.senderCollection = senders;
    });

  }

  onRowSelect(event) {
    this.newSender = false;
    this.sender = this.cloneItem(event.data);
    this.displayDialog = true;
  }

  showDialogToAdd() {
    this.newSender = true;
    this.sender = new SenderModel();
    this.displayDialog = true;
  }

  cloneItem(c: SenderModel): SenderModel {
    let a = new SenderModel();
    for (let prop in c) {
      a[prop] = c[prop];
    }

    return a;
  }

  save() {
    let senders = [...this.senderCollection];
    
    if (this.newSender) {
      this.userService.addProdAutoSender(this.sender).subscribe(result => {
        if (result) {
          this.sender.partnerLabel = this.allPartners.find(x => x.value == this.sender.partnerId).label;
          senders.push(this.sender);

          this.messageService.add({ severity: 'success', summary: 'Information', detail: "L'emetteur a été ajouté avec succès", life: 4000 });

          this.senderCollection = senders;
          this.sender = null;
          this.displayDialog = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "l'emetteur existe déjà.", life: 4000 });
        }
      });
    }
    else {
      this.userService.editProdAutoSender(this.sender).subscribe(result => {
        if (result) {
          this.sender.partnerLabel = this.allPartners.find(x => x.value == this.sender.partnerId).label;
          senders[this.senderCollection.indexOf(this.selectedSender)] = this.sender;

          this.messageService.add({ severity: 'success', summary: 'Information', detail: "L'emetteur a été modifié avec succès", life: 4000 });

          this.senderCollection = senders;
          this.sender = null;
          this.displayDialog = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "l'emetteur existe déjà.", life: 4000 });
        }
      });
    }

  }

  delete() {

    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer cet emetteur ?',
      header: 'Confirmation de suppression',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.userService.deleteProdAutoSender(this.selectedSender).subscribe(result => {
          if (result) {
            this.messageService.add({ severity: 'success', summary: 'Information', detail: "L'emetteur a été supprimé avec succès", life: 4000 });

            let index = this.senderCollection.indexOf(this.selectedSender);
            this.senderCollection = this.senderCollection.filter((val, i) => i != index);
            this.sender = null;
            this.displayDialog = false;
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur est survenue lors de la suppression. Veuillez réessayer plus tard.", life: 4000 });
          }
        });
      },
      reject: () => {
      }
    });
    
    
  }


}
