import { Component, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { NewPartner } from "../../models/NewPartner";
import { ReferentielService } from "../../Services/referentiel.service";

@Component({
  templateUrl: './addPartner.component.html',
  providers: [MessageService]
})
export class AddPartner {

  partnerForm: FormGroup;
  loading: boolean;
  submitted = false;
  loadingButton: boolean;
  showOkoroParams: boolean;
  showMailevaParams: boolean;
  certificate: any;
  partnerToAdd: NewPartner;
  @ViewChild('fileUpload', { static: false }) fileUpload: any;


  constructor(private partnerFormBuilder: FormBuilder, private referentielService: ReferentielService, private messageService: MessageService) {
    this.loading = true;
    this.loadingButton = false;
    this.showOkoroParams = false;
  }

  ngOnInit() {
    this.partnerForm = this.partnerFormBuilder.group({
      code: ['', [Validators.required, Validators.min(1), Validators.max(99)]],
      label: ['', Validators.required],
      dolly: ['', Validators.required],
      certificate: ['', Validators.required],
      certificate_password: ['', Validators.required],
      isOkoro: [''],
      okoroUrl: ['', Validators.required],
      login: ['', Validators.required],
      password: ['', Validators.required],
      creationSerie: ['', Validators.required],
      depositSerie: ['', Validators.required],
      okoroOrganizationCode: [''],
      okoroOrganizationDepth: [''],
      okoroActivityCode: [''],
      okoroActivityDepth: [''],
      okoroActivity2Code: [''],
      okoroActivity2Depth: [''],
      okoroUserProfil: [''],
      okoroAdminProfil: [''],
      isMaileva: [''],
      mailevaLogin: ['', Validators.required],
      mailevaPassword: ['', Validators.required],
      mailevaSftpIdConfig: ['', Validators.required],
      mailevaSempPath: ['', Validators.required],
      mailevaNotificationFolder: ['', Validators.required],
      mailevaNotificationLogin: ['', Validators.required],
      docaposteClientCode: ['', Validators.required],
      mailevaSftpSempUrl: ['', Validators.required]
    });

    this.f.okoroUrl.disable();
    this.f.login.disable();
    this.f.password.disable();
    this.f.creationSerie.disable();
    this.f.depositSerie.disable();

    this.f.isOkoro.setValue(false);

    this.f.mailevaLogin.disable();
    this.f.mailevaPassword.disable();
    this.f.mailevaSftpIdConfig.disable();
    this.f.mailevaSempPath.disable();
    this.f.mailevaNotificationFolder.disable();
    this.f.mailevaNotificationLogin.disable();
    this.f.docaposteClientCode.disable();
    this.f.mailevaSftpSempUrl.disable();

    this.f.isMaileva.setValue(false);

    this.loading = false;

    this.partnerToAdd = new NewPartner();
  }

  // convenience getter for easy access to form fields
  get f() { return this.partnerForm.controls; }

  onChangeOkoro(e) {

    if (e.value == true) {
      this.showOkoroParams = true;
      this.f.okoroUrl.enable();
      this.f.login.enable();
      this.f.password.enable();
      this.f.creationSerie.enable();
      this.f.depositSerie.enable();
    }
    else {
      this.showOkoroParams = false;
      this.f.okoroUrl.disable();
      this.f.login.disable();
      this.f.password.disable();
      this.f.creationSerie.disable();
      this.f.depositSerie.disable();
    }
  }

  onChangeMaileva(e) {

    if (e.value == true) {
      this.showMailevaParams = true;

      this.f.mailevaLogin.enable();
      this.f.mailevaPassword.enable();
      this.f.mailevaSftpIdConfig.enable();
      this.f.mailevaSempPath.enable();
      this.f.mailevaNotificationFolder.enable();
      this.f.mailevaNotificationLogin.enable();
      this.f.docaposteClientCode.enable();
      this.f.mailevaSftpSempUrl.enable();
    }
    else {
      this.showMailevaParams = false;

      this.f.mailevaLogin.disable();
      this.f.mailevaPassword.disable();
      this.f.mailevaSftpIdConfig.disable();
      this.f.mailevaSempPath.disable();
      this.f.mailevaNotificationFolder.disable();
      this.f.mailevaNotificationLogin.disable();
      this.f.docaposteClientCode.disable();
      this.f.mailevaSftpSempUrl.disable();
    }
  }

  onCertificateUpload(event) {
    for (let file of event.files) {
      this.certificate = file;
    }

    this.f.certificate.setValue(this.certificate);
  }

  onSubmit() {

    this.loadingButton = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.partnerForm.invalid) {
      this.loadingButton = false;
      return;
    }

    this.partnerToAdd.code = this.partnerForm.value.code;
    this.partnerToAdd.label = this.partnerForm.value.label;
    this.partnerToAdd.dolly = this.partnerForm.value.dolly;
    this.partnerToAdd.certificate = this.partnerForm.value.certificate;
    this.partnerToAdd.certificate_password = this.partnerForm.value.certificate_password;
    this.partnerToAdd.isOkoro = this.partnerForm.value.isOkoro;
    this.partnerToAdd.okoroUrl = this.partnerForm.value.okoroUrl;
    this.partnerToAdd.okoroLogin = this.partnerForm.value.login;
    this.partnerToAdd.okoroPassword = this.partnerForm.value.password;
    this.partnerToAdd.okoroCreationSerie = this.partnerForm.value.creationSerie;
    this.partnerToAdd.okoroDepositSerie = this.partnerForm.value.depositSerie;
    this.partnerToAdd.okoroOrganizationCode = this.partnerForm.value.okoroOrganizationCode;
    this.partnerToAdd.okoroOrganizationDepth = this.partnerForm.value.okoroOrganizationDepth;
    this.partnerToAdd.okoroActivityCode = this.partnerForm.value.okoroActivityCode;
    this.partnerToAdd.okoroActivityDepth = this.partnerForm.value.okoroActivityDepth;
    this.partnerToAdd.okoroActivity2Code = this.partnerForm.value.okoroActivity2Code;
    this.partnerToAdd.okoroActivity2Depth = this.partnerForm.value.okoroActivity2Depth;
    this.partnerToAdd.okoroUserProfil = this.partnerForm.value.okoroUserProfil;
    this.partnerToAdd.okoroAdminProfil = this.partnerForm.value.okoroAdminProfil;


    this.partnerToAdd.isMaileva = this.partnerForm.value.isMaileva;
    this.partnerToAdd.mailevaLogin = this.partnerForm.value.mailevaLogin;
    this.partnerToAdd.mailevaPassword = this.partnerForm.value.mailevaPassword;
    this.partnerToAdd.mailevaSftpIdConfig = this.partnerForm.value.mailevaSftpIdConfig;
    this.partnerToAdd.mailevaSempPath = this.partnerForm.value.mailevaSempPath;
    this.partnerToAdd.mailevaNotificationFolder = this.partnerForm.value.mailevaNotificationFolder;
    this.partnerToAdd.mailevaNotificationLogin = this.partnerForm.value.mailevaNotificationLogin;
    this.partnerToAdd.docaposteClientCode = this.partnerForm.value.docaposteClientCode;
    this.partnerToAdd.mailevaSftpSempUrl = this.partnerForm.value.mailevaSftpSempUrl;

    this.referentielService.addNewPartner(this.partnerToAdd).subscribe(x => {
      this.loadingButton = false;
      this.submitted = false;

      if (x != null) {
        this.partnerForm.reset();
        this.f.isOkoro.setValue(false);
        this.showOkoroParams = false;
        this.fileUpload.clear();
        this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le partenaire a été ajouté ou mis à jour avec succès", life: 4000 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur est survenue lors de l'ajout ou mise à jour du partenaire.", life: 4000 });
      }     

    });

  }

  onReset() {
    this.submitted = false;
    this.showOkoroParams = false;
    this.fileUpload.clear();
    this.partnerForm.reset();
    this.f.isOkoro.setValue(false);
    this.f.isMaileva.setValue(false);
  }


}
