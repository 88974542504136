import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CoffreSender } from "../models/CoffreSender";
import { OkoroDocumentType } from "../models/OkoroDocumentType";

@Injectable()
export class OkoroService {

  url: string;

  constructor(
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl;
  }

  getAllCoffreSender() {
    return this.http.get<CoffreSender[]>(this.url + 'api/okoro/GetCoffreSender/');
  }

  getAllOkoroDocumentTypes() {
    return this.http.get<OkoroDocumentType[]>(this.url + 'api/okoro/GetAllOkoroDocumentTypes/');
  }

  addOkoroDocumentType(okoroDocumentType: OkoroDocumentType) {
    return this.http.post<any>(this.url + 'api/okoro/AddOkoroDocumentType/', okoroDocumentType);
  }

  editOkoroDocumentType(okoroDocumentType: OkoroDocumentType) {
    return this.http.post<any>(this.url + 'api/okoro/EditOkoroDocumentType/', okoroDocumentType);
  }

  deleteOkoroDocumentType(okoroDocumentType: OkoroDocumentType) {
    return this.http.post<any>(this.url + 'api/okoro/DeleteOkoroDocumentType/', okoroDocumentType);
  }

  deleteCoffreSender(coffreSender: CoffreSender) {
    return this.http.post<any>(this.url + 'api/okoro/DeleteCoffreSender/', coffreSender);
  }

}
