import { Component, OnInit, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../Services/authentication.service";
import { BehaviorSubject } from "rxjs";
import { RoleEnum } from '../models/roleEnum';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
  //@Output() allUsers: EventEmitter<any> = new EventEmitter<any>();

  loginForm: FormGroup;
  submitClick = false;
  submitted = false;
  returnUrl: String;
  error = false;
  connexionError = false;
  tryingCountAttempted = false;
  tryingCount: number;
  innactifAccount = false;
  http: HttpClient;
  url: string;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService, http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    
    this.http = http;
    this.url = baseUrl;
  }
  
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset Login status
    this.authenticationService.logout();

    // get return url from route parameters or defaukt to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to from fields
  get formData() { return this.loginForm.controls; }

  onLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.submitClick = true;
    this.error = false;
    this.tryingCountAttempted = false;
    this.innactifAccount = false;

    this.authenticationService.login(this.formData.username.value, this.formData.password.value)
      .pipe(first())
      .subscribe(
        data => {

          this.connexionError = false;
          if (data.currentUser != null) {
            this.submitClick = false;

            if (data.currentUser.tryingCount < 5) {
              if (data.currentUser.active == true) {
                if (data.currentUser.username != null) {
                  if (data.currentUser.roleId == RoleEnum.PRODAUTO_PART || data.currentUser.roleId == RoleEnum.PRODAUTO_SENDER) {
                    this.router.navigate(['spoolBack']);
                  }
                  else {
                    this.router.navigate([this.returnUrl]);
                  }
                }
                else {
                  this.error = true;
                  this.tryingCountAttempted = true;
                  this.tryingCount = 5 - (data.currentUser.tryingCount + 1);
                }                
              }
              else {
                this.innactifAccount = true;
              }
            }


            //if (data.currentUser.tryingCount >= 5) {
            //  if (data.currentUser.active == true) {
            //    this.tryingCountAttempted = false;
            //    this.innactifAccount = false;
            //  } else {
            //    this.innactifAccount = true;
            //  }              
            //} else {
            //  //this.tryingCountAttempted = false;
            //  this.router.navigate([this.returnUrl]);
            //}            
          }
          else {
            this.error = true;
            this.submitClick = false;
            this.innactifAccount = false;
            this.tryingCountAttempted = false;
          }
        },
        error => {
          this.connexionError = true;
          this.submitClick = false;
          this.innactifAccount = false;
          this.tryingCountAttempted = false;
        });
  }
}
