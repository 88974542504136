export class UserToAdd {
  id: number;
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  emailAddress: string;
  partnerOrClientId: string;
  role: string;
  active: boolean;
}
