import { MessageService, DialogService, ConfirmationService } from 'primeng/api';
import { Component, ViewEncapsulation } from '@angular/core';
import { OkoroService } from '../../Services/okoro.service';
import { CoffrePartner } from '../../models/CoffrePartner';
import { ReferentielService } from '../../Services/referentiel.service';
import { PartnerModel } from '../../models/PartnerModel';
import { CoffreSender } from '../../models/CoffreSender';


@Component({
  templateUrl: './senderCoffreManagement.component.html',
  styleUrls: ['./senderCoffreManagement.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, MessageService, ConfirmationService]
})
export class senderCoffreManagement {

  loading = true;
  hide = true;
  displayDialog: boolean;
  coffreSenderCollection: any[];
  columns: any[];
  coffreSender: CoffreSender;
  totalRecords: number;
  originalPartners: PartnerModel[];
  allPartners: any[];
  selectedPartner: any;

  constructor(private okoroService: OkoroService, private referentielService: ReferentielService, private messageService: MessageService, private confirmationService: ConfirmationService) {

  }

  ngOnInit() {

    this.columns = [
      { field: 'partnerLabel', header: 'Partenaire' },
      { field: 'pid', header: 'Pid' },
      { field: 'salle', header: 'Salle' },
      { field: 'coffre', header: 'Coffre' },
      { field: 'idMag', header: 'Id transfer' },
      { field: 'status', header: 'Statut' },
      { field: 'creationDate', header: 'Date de création' },
      { field: 'groupLabel', header: 'Groupe' }
    ];

    this.referentielService.getAllPartners().subscribe(partners => {
      this.originalPartners = partners;

      this.allPartners = partners.map(x => (
        {
          value: x.id,
          label: x.label
        }));
      this.allPartners.unshift({
        value: null,
        label: null
      });

      this.okoroService.getAllCoffreSender().subscribe(coffre => {
        
        this.coffreSenderCollection = coffre;

        if (this.coffreSenderCollection != null) {
          this.totalRecords = this.coffreSenderCollection.length;

          this.coffreSenderCollection.forEach(x => x.partnerLabel = this.allPartners.find(y => y.value == x.partnerId).label);

        }

        this.loading = false;
      });
      
    });
  }

  deleteRow(coffreSender: CoffreSender) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
      header: 'Confirmation de suppression',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.okoroService.deleteCoffreSender(coffreSender).subscribe(result => {
          if (result) {
            this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le coffre émetteur a été supprimé avec succès", life: 4000 });

            let index = this.coffreSenderCollection.indexOf(coffreSender);
            this.coffreSenderCollection = this.coffreSenderCollection.filter((val, i) => i != index);
            this.displayDialog = false;
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur est survenue lors de la suppression. Veuillez réessayer plus tard.", life: 4000 });
          }
        });
      },
      reject: () => {
      }
    });
  }

}
