import { Component } from '@angular/core';
import { Location } from '@angular/common'

@Component({
  templateUrl: './forbidden.component.html'
})
export class ForbiddenComponent {
  constructor(private location: Location) { }

  back(): void {
    console.log(this.location.back());
    this.location.back()
  }
}
