import { Component } from '@angular/core';
import { AuthenticationService } from '../Services/authentication.service';
import { Observable } from 'rxjs';
import { ActifUser } from '../models/ActifUser';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  currentUser: ActifUser;
  isLoggedIn$: Observable<boolean>;

  constructor(
    private authenticationService: AuthenticationService) {
    this.currentUser = authenticationService.getUserAuthenticated();
  }

  ngOnInit() {
    this.isLoggedIn$ = this.authenticationService.isLoggedIn;
    this.authenticationService.getEmitter().subscribe((user) => {
      this.currentUser = user;
    })
  }

  logout() {
    this.authenticationService.logout();
  }
}
