import { ViewEncapsulation, Component } from "@angular/core";
import { DynamicDialogConfig } from "primeng/api";
import { SpoolModel } from "../../../models/SpoolModel";
import { FlowTrackingService } from "../../../Services/flowTracking.service";

@Component({
  templateUrl: './spoolDetail.component.html',
  styleUrls: ['./spoolDetail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpoolDetail {

  selectedSpool: SpoolModel;
  spoolHistory: SpoolModel[];
  detailLoading: boolean;
  statusCode: any;
  stepCode: any;
  zipNamesList: any;
  listLength: number;
  fileUrl: any;

  constructor(private rabbitMqService: FlowTrackingService, public config: DynamicDialogConfig) {
    this.detailLoading = true;
  }

  ngOnInit() {

    let fdr = this.config.data.numFdr;
    this.selectedSpool = this.config.data.selectedSpool;

    this.rabbitMqService.getSpoolHistory(fdr).subscribe(result => {
      this.spoolHistory = result;

      this.zipNamesList = this.spoolHistory.filter(x => x.zipName != "" && (x.stepCode == 40 || x.stepCode == 30)).reduce((r, a) => {
        r[a.zipName] = [...r[a.zipName] || [], a];
        return r;
      }, {});
      
      this.listLength = Object.keys(this.zipNamesList).length;

      this.detailLoading = false;
    });
    
  }

  downloadFile(selectedSpool: SpoolModel) {
    var splitedFileName = selectedSpool.zipName.split('.');
    var filename = splitedFileName[0];
    const arrayBuffer = base64ToArrayBuffer(selectedSpool.xmlContent);
    createAndDownloadBlobFile(arrayBuffer, filename, "xml");
  }


}

export function base64ToArrayBuffer(data: string) {
  const bytes = new Uint8Array(data.length);
  return bytes.map((byte, i) => data.charCodeAt(i));
}

export function createAndDownloadBlobFile(body, filename, extension = 'txt') {
  const blob = new Blob([body]);
  const fileName = `${filename}.${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
