export enum GlobalStatusEnum {
  'RECEIVED' = 100,
  'ACCEPTED' = 105,
  'IN PROGRESS' = 110,
  'OK' = 150,
  'PARTIAL' = 160,
  'WARNING' = 180,
  'REFUSED' = 185,
  'REJECTED' = 190,
  'KO' = 199
}
