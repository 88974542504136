import { MessageService, DialogService, MenuItem, ConfirmationService } from 'primeng/api';
import { Component, ViewEncapsulation } from '@angular/core';
import { FlowTrackingService } from '../../Services/flowTracking.service';
import { PartnerModel } from 'src/app/models/PartnerModel';
import { UserService } from 'src/app/Services/user.service';


@Component({
  templateUrl: './partnerManagement.component.html',
  styleUrls: ['./partnerManagement.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, MessageService, ConfirmationService]
})
export class partnerManagement {

  loading = true;
  displayDialog: boolean;
  columns: any[];
  partnerCollection: any[];
  partner: PartnerModel;
  selectedPartner: PartnerModel;
  newPartner: boolean;

  constructor(private userService: UserService, private messageService: MessageService, private confirmationService: ConfirmationService) {

  }

  ngOnInit() {

    this.columns = [
      { field: 'code', header: 'Code' },
      { field: 'label', header: 'Label' }
    ];

    this.userService.getAllProdAutoPartners().subscribe(partners => {
      this.loading = false;
      this.partnerCollection = partners;
    });

  }

  onRowSelect(event) {
    this.newPartner = false;
    this.partner = this.cloneItem(event.data);
    this.displayDialog = true;
  }

  showDialogToAdd() {
    this.newPartner = true;
    this.partner = new PartnerModel();
    this.displayDialog = true;
  }

  cloneItem(c: PartnerModel): PartnerModel {
    let a = new PartnerModel();
    for (let prop in c) {
      a[prop] = c[prop];
    }

    return a;
  }

  save() {
    let partners = [...this.partnerCollection];
    if (this.newPartner) {
      this.userService.addProdAutoPartner(this.partner).subscribe(result => {
        if (result) {
          partners.push(this.partner);

          this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le partenaire a été ajouté avec succès", life: 4000 });

          this.partnerCollection = partners;
          this.partner = null;
          this.displayDialog = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "le partenaire existe déjà.", life: 4000 });
        }
      });
    }
    else {
      this.userService.editProdAutoPartner(this.partner).subscribe(result => {
        if (result) {
          partners[this.partnerCollection.indexOf(this.selectedPartner)] = this.partner;

          this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le partenaire a été modifié avec succès", life: 4000 });

          this.partnerCollection = partners;
          this.partner = null;
          this.displayDialog = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "le partenaire existe déjà.", life: 4000 });
        }
      });
    }

  }

  delete() {

    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer cet emetteur ?',
      header: 'Confirmation de suppression',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.userService.deleteProdAutoPartner(this.selectedPartner).subscribe(result => {
          if (result) {
            this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le partenaire a été supprimé avec succès", life: 4000 });

            let index = this.partnerCollection.indexOf(this.selectedPartner);
            this.partnerCollection = this.partnerCollection.filter((val, i) => i != index);
            this.partner = null;
            this.displayDialog = false;
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur est survenue lors de la suppression. Veuillez vérifier que le partenaire n'a pas d'emetteurs dépendants.", life: 6000 });
          }
        });
      },
      reject: () => {
      }
    });

    
  }
  

}
