import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleEnum } from "../../models/roleEnum";
import { UserService } from "../../Services/user.service";
import { UserToAdd } from "../../models/UserToAdd";
import { MessageService } from 'primeng/api';

@Component({
  templateUrl: './addUser.component.html',
  providers: [MessageService]
})
export class AddUser {

  userForm: FormGroup;
  roles: any[];
  originalPartners: any[];
  originalProdAutoPartners: any[];
  originalSenders: any[];
  originalProdAutoSenders: any[];
  partners: any[];
  senders: any[];
  disablePartnerDropdown: boolean;
  disableSenderDropdown: boolean;
  loading: boolean;
  submitted = false;
  loadingButton: boolean;


  constructor(private userFormBuilder: FormBuilder, private userService: UserService, private messageService: MessageService) {
    this.disablePartnerDropdown = true;
    this.disableSenderDropdown = true;
    this.loading = true;
    this.loadingButton = false;
  }

  ngOnInit() {
    this.userForm = this.userFormBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      username: ['', Validators.required],
      role: ['', Validators.required],
      partner: ['', Validators.required],
      sender: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z]{2,4}$")]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
        validator: this.MustMatch('password', 'confirmPassword')
      });

    this.f.partner.disable();
    this.f.sender.disable();

    this.userService.getAllRoles().subscribe(roles => {
      this.roles = roles;
     
      this.loading = false;
    });
    this.userService.getAllPartners().subscribe(partners => {
      this.originalPartners = partners;
    });
    this.userService.getAllSenders().subscribe(senders => {
      this.senders = this.originalSenders = senders;
    });
    this.userService.getAllProdAutoPartners().subscribe(partners => {
      this.originalProdAutoPartners = partners;
    });
    this.userService.getAllProdAutoSenders().subscribe(senders => {
      this.senders = this.originalProdAutoSenders = senders;
    });
   
  }

  // convenience getter for easy access to form fields
  get f() { return this.userForm.controls; }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  onRoleChanged(e) {
    this.f.partner.setValue("");
    this.f.sender.setValue("");

    if ((e.target.selectedIndex == RoleEnum.PART)
      || (e.target.selectedIndex == RoleEnum.SENDER)) {
      // enable partner dropdown && disable sender dropdown
      this.partners = this.originalPartners;

      this.f.partner.enable();
      this.f.sender.disable();
    }
    else if ((e.target.selectedIndex == RoleEnum.PRODAUTO_PART)
      || (e.target.selectedIndex == RoleEnum.PRODAUTO_SENDER)) {
      this.partners = this.originalProdAutoPartners;

      this.f.partner.enable();
      this.f.sender.disable();
    }
    else {
      this.f.partner.disable();
      this.f.sender.disable();
    }
  }

  onPartnerChanged(e) {
    let roleCode = this.roles.find(x => x.label == this.f.role.value).code;
    let roleId = RoleEnum[roleCode];

    let partnerCode = this.userForm.get('partner').value;

    let partner = this.originalPartners.find(partner => partner.code == partnerCode);    

    if (roleId == RoleEnum.SENDER.toString()) {

      if (e.target.selectedIndex == 0) {
        this.f.sender.setValue("");
        this.f.sender.disable();
      }
      else {
        this.senders = this.originalSenders.filter(sender => sender.partnerId == partner.id);
        this.f.sender.enable();
      }
    }
    else if (roleId == RoleEnum.PRODAUTO_SENDER.toString()) {
      if (e.target.selectedIndex == 0) {
        this.f.sender.setValue("");
        this.f.sender.disable();
      }
      else {
        partner = this.originalProdAutoPartners.find(partner => partner.code == partnerCode);
        console.log(this.originalProdAutoPartners);
        console.log(this.originalProdAutoSenders);
        console.log(partner.id);
        this.senders = this.originalProdAutoSenders.filter(sender => sender.partnerId == partner.id);
        this.f.sender.enable();
      }
    }
  }


  onSubmit() {
    this.loadingButton = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      this.loadingButton = false;
      return;
    }

    let roleCode = this.roles.find(x => x.label == this.f.role.value).code;
    let roleId = RoleEnum[roleCode];
    

    let userToBeAdded: UserToAdd = new UserToAdd();

    userToBeAdded.firstname = this.userForm.value.firstname;
    userToBeAdded.lastname = this.userForm.value.lastname;
    userToBeAdded.username = this.userForm.value.username;
    userToBeAdded.emailAddress = this.userForm.value.email;
    userToBeAdded.role = roleCode;
    userToBeAdded.password = this.userForm.value.password;

    if (roleId != null) {
      if (roleId == RoleEnum.PART.toString()) {
        userToBeAdded.partnerOrClientId = this.partners.find(x => x.code == this.userForm.value.partner).id;
      }
      else if (roleId == RoleEnum.SENDER.toString() || roleId == RoleEnum.PRODAUTO_SENDER.toString()) {
        userToBeAdded.partnerOrClientId = this.senders.find(x => x.label == this.userForm.value.sender).id;
      }
      else {
        userToBeAdded.partnerOrClientId = null;
      }
    }

    this.userService.addUser(userToBeAdded).subscribe(x => {
      this.loadingButton = false;
      this.submitted = false;
      if (x != null) {
        this.userForm.reset();
        this.messageService.add({ severity: 'success', summary: 'Information', detail: "L'utilisateur a été ajouté avec succès", life: 4000 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "le nom d'utilisateur existe déjà. Merci de choisir un autre nom d'utilisateur.", life: 4000 });
      }

    })

  }

  onReset() {
    this.submitted = false;
    this.userForm.reset();
  }


}
