import { MessageService, DialogService, ConfirmationService } from 'primeng/api';
import { Component, ViewEncapsulation } from '@angular/core';
import { OkoroService } from '../../Services/okoro.service';
import { OkoroDocumentType } from '../../models/OkoroDocumentType';


@Component({
  templateUrl: './documentTypeManagement.component.html',
  styleUrls: ['./documentTypeManagement.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, MessageService, ConfirmationService]
})
export class documentTypeManagement {

  loading = true;
  displayDialog: boolean;
  okoroDocumentTypesCollection: any[];
  columns: any[];
  types: any[];
  newOkoroDocumentType: boolean;
  okoroDocumentType: OkoroDocumentType;
  selectedOkoroDocumentType: OkoroDocumentType;
  totalRecords: number;

  constructor(private okoroService: OkoroService, private messageService: MessageService, private confirmationService: ConfirmationService) {

  }

  ngOnInit() {

    this.columns = [
      { field: 'Code', header: 'Code' },
      { field: 'Label', header: 'Label' },
      { field: 'Dua', header: 'Dua' }
    ];
      this.okoroService.getAllOkoroDocumentTypes().subscribe(doc => {
        this.loading = false;
        this.okoroDocumentTypesCollection = doc;

        if (this.okoroDocumentTypesCollection != null) {
          this.totalRecords = this.okoroDocumentTypesCollection.length;
        }

      });
  }

  onRowSelect(event) {
    this.newOkoroDocumentType = false;
    this.okoroDocumentType = this.cloneItem(event.data);
    this.displayDialog = true;
  }

  showDialogToAdd() {
    this.newOkoroDocumentType = true;
    this.okoroDocumentType = new OkoroDocumentType();
    this.displayDialog = true;
  }

  cloneItem(c: OkoroDocumentType): OkoroDocumentType {
    let a = new OkoroDocumentType();
    for (let prop in c) {
      a[prop] = c[prop];
    }
    return a;
  }

  save() {
    let orgas = [...this.okoroDocumentTypesCollection];
    if (this.newOkoroDocumentType) {
      this.okoroService.addOkoroDocumentType(this.okoroDocumentType).subscribe(result => {
        if (result) {
          orgas.push(this.okoroDocumentType);

          this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le type de document a été ajouté avec succès", life: 4000 });

          this.okoroDocumentTypesCollection = orgas;
          this.okoroDocumentType = null;
          this.displayDialog = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Le type de document existent déjà.", life: 4000 });
        }
      });
    }
    else {
      this.okoroService.editOkoroDocumentType(this.okoroDocumentType).subscribe(result => {
        if (result) {
          orgas[this.okoroDocumentTypesCollection.indexOf(this.selectedOkoroDocumentType)] = this.okoroDocumentType;

          this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le type de document a été modifié avec succès", life: 4000 });

          this.okoroDocumentTypesCollection = orgas;
          this.okoroDocumentType = null;
          this.displayDialog = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Le type de document existent déjà.", life: 4000 });
        }
      });
    }

  }

  delete() {

    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
      header: 'Confirmation de suppression',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.okoroService.deleteOkoroDocumentType(this.selectedOkoroDocumentType).subscribe(result => {
          if (result) {
            this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le type de document a été supprimé avec succès", life: 4000 });

            let index = this.okoroDocumentTypesCollection.indexOf(this.selectedOkoroDocumentType);
            this.okoroDocumentTypesCollection = this.okoroDocumentTypesCollection.filter((val, i) => i != index);
            this.okoroDocumentType = null;
            this.displayDialog = false;
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur est survenue lors de la suppression. Veuillez réessayer plus tard.", life: 4000 });
          }
        });
      },
      reject: () => {
      }
    });
  }

}
