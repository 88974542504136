export enum DigiposteStatusEnum {
  'TO DO' = 300,
  'IN PROGRESS' = 310,
  'SUBMITTED TO DIGIPOSTE' = 340,
  'OK' = 350,
  'FORWARDED TO MAILEVA' = 370,
  'FORWARDED TO SENDER' = 375,
  'WARN' = 380,
  'KO' = 399
}
