import { Component } from '@angular/core';
import { UserService } from '../Services/user.service';
import { RoleEnum } from '../models/roleEnum';
import { EditUsers } from './EditUsers/editUsers.component';
import { DialogService, ConfirmationService, MessageService } from 'primeng/api';
import { UserModel } from '../models/UserModel';
import { ActifUser } from '../models/ActifUser';
import { AuthenticationService } from '../Services/authentication.service';

@Component({
  templateUrl: './userManagement.component.html',
  styleUrls: ['./userManagement.component.css'],
  providers: [DialogService, ConfirmationService, MessageService]
})
export class UserManagement {

  currentUser: ActifUser;
  ihmUsersCollection: any[];
  wsUsersCollection: any[];
  columns: any[];
  loading1: boolean;
  loading2: boolean;
  loading3: boolean;
  loading4: boolean;
  loading5: boolean;
  clonedUsers: { [s: string]: any; } = {};
  roles: any[];
  wsRoles: any[];
  partners: any[];
  senders: any[];
  originalPartners: any[];
  originalProdAutoPartners: any[];
  originalSenders: any[];
  originalWsSenders: any[];
  originalProdAutoSenders: any[];
  disablePartnerDropdown: boolean;
  disableSenderDropdown: boolean;

  constructor(private userService: UserService, public dialogService: DialogService, private confirmationService: ConfirmationService, private messageService: MessageService, private authenticationService: AuthenticationService) {
    this.loading1 = true;
    this.loading2 = true;
    this.loading3 = true;
    this.loading4 = true;
    this.loading5 = true;
    this.currentUser = authenticationService.getUserAuthenticated();
  }

  ngOnInit() {

    this.columns = [
      { field: 'active', header: 'Actif' },
      { field: 'lastname', header: 'Nom' },
      { field: 'firstname', header: 'Prénom' },
      { field: 'username', header: "Nom d'utilisateur" },
      { field: 'emailAddress', header: 'Adresse Email' },
      { field: 'roleLabel', header: 'Rôle' },
      { field: 'partnerLabel', header: 'Partenaire' },
      { field: 'clientLabel', header: 'Client' },
      { field: '', header: '' }
    ];

    this.userService.getAllRoles().subscribe(roles => {
      this.roles = roles;
    });
    this.userService.getAllWsRoles().subscribe(roles => {
      this.wsRoles = roles;
      this.loading1 = false;
    });
    this.userService.getAllPartners().subscribe(partners => {
      this.originalPartners = partners;
      this.loading3 = false;
    });
    this.userService.getAllSenders().subscribe(senders => {
      this.originalSenders = senders;
      this.loading4 = false;
    });
    this.userService.getAllWsSenders().subscribe(senders => {
      this.originalWsSenders = senders;
      this.loading4 = false;
    });
    this.userService.getAllProdAutoPartners().subscribe(partners => {
      this.originalProdAutoPartners = partners;
    });
    this.userService.getAllProdAutoSenders().subscribe(senders => {
      this.originalProdAutoSenders = senders;
    });

    this.userService.getAllIhmUsers().subscribe(users => {
      this.ihmUsersCollection = users;
      this.loading2 = false;
    });

    this.userService.getAllWsUsers().subscribe(users => {
      this.wsUsersCollection = users;
      this.loading5 = false;
    });

    this.disablePartnerDropdown = true;
    this.disableSenderDropdown = true;

  }
  
  onRowEdit(user: any) {

    const ref = this.dialogService.open(EditUsers, {
      data: {
        // Passing data to documentData component
        user: user,
        roles: this.roles,
        partners: this.originalPartners,
        senders: this.originalSenders,
        prodAutoPartners: this.originalProdAutoPartners,
        prodAutoSenders: this.originalProdAutoSenders
      },
      header: "Nom d'utilisateur : " + user.username,
      width: '70%'
    });

    ref.onClose.subscribe((user: UserModel) => {
      if (user) {
        let updateItem = this.ihmUsersCollection.find(currentUser => user.id == currentUser.id);

        let index = this.ihmUsersCollection.indexOf(updateItem);

        this.ihmUsersCollection[index] = user;

        this.messageService.add({ severity: 'success', summary: 'Information', detail: "L'utilisateur a été modifié avec succès", life: 4000 });
      }
    });
  }

  onWsRowEdit(user: any) {

    const ref = this.dialogService.open(EditUsers, {
      data: {
        // Passing data to documentData component
        user: user,
        roles: this.wsRoles,
        partners: this.originalPartners,
        senders: this.originalWsSenders,
        isWsUser: true
      },
      header: "Nom d'utilisateur : " + user.username,
      width: '70%'
    });

    ref.onClose.subscribe((user: UserModel) => {
      if (user) {
        let updateItem = this.wsUsersCollection.find(currentUser => user.id == currentUser.id);

        let index = this.wsUsersCollection.indexOf(updateItem);

        this.wsUsersCollection[index] = user;

        this.messageService.add({ severity: 'success', summary: 'Information', detail: "L'utilisateur a été modifié avec succès", life: 4000 });
      }
    });
  }

  onRowDelete(user: UserModel) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sur de vouloir supprimer cet utilisateur?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.confirmDelete(user.id);
      }
    });
  }

  onWsRowDelete(user: UserModel) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sur de vouloir supprimer cet utilisateur?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.wsconfirmDelete(user.id);
      }
    });
  }

  confirmDelete(userId: number) {

    this.userService.deleteUser(userId).subscribe(x => {
      if (x == true) {
        let updateItem = this.ihmUsersCollection.find(currentUser => userId == currentUser.id);

        let index = this.ihmUsersCollection.indexOf(updateItem);

        this.ihmUsersCollection.splice(index, 1);

        this.messageService.add({ severity: 'success', summary: 'Information', detail: "L'utilisateur a été supprimé avec succès", life: 4000 });
      }
    });
    
  }

  wsconfirmDelete(userId: number) {

    this.userService.deleteWsUser(userId).subscribe(x => {
      if (x == true) {
        let updateItem = this.wsUsersCollection.find(currentUser => userId == currentUser.id);

        let index = this.wsUsersCollection.indexOf(updateItem);

        this.wsUsersCollection.splice(index, 1);

        this.messageService.add({ severity: 'success', summary: 'Information', detail: "L'utilisateur a été supprimé avec succès", life: 4000 });
      }
    });

  }

  onRowEditCancel(user: any, index: number) {
    this.ihmUsersCollection[index] = this.clonedUsers[user.id];
    delete this.clonedUsers[user.id];
  }
}
