import { MessageService, DialogService, LazyLoadEvent } from 'primeng/api';
import { Component, ViewEncapsulation } from '@angular/core';
import { FlowTrackingService } from 'src/app/Services/flowTracking.service';
import { SpoolDetail } from './SpoolDetail/SpoolDetail.component';
import { SpoolModel } from '../../models/SpoolModel';
import { ActifUser } from '../../models/ActifUser';
import { RoleEnum } from '../../models/roleEnum';
import { AuthenticationService } from '../../Services/authentication.service';

@Component({
  templateUrl: './spoolBack.component.html',
  styleUrls: ['./spoolBack.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, MessageService]
})
export class SpoolBack {

  loading = true;
  detailLoading = false;
  spoolCollection: SpoolModel[];
  spoolColumns: any[];
  fr: any;
  yearRange: any;
  event: LazyLoadEvent;
  totalRecords: number;
  currentUser: ActifUser;
  requestId: string;

  constructor(private rabbitMqService: FlowTrackingService, public dialogService: DialogService, private authenticationService: AuthenticationService) {
    this.currentUser = authenticationService.getUserAuthenticated();

    if (this.currentUser.roleId == RoleEnum.PART || this.currentUser.roleId == RoleEnum.PRODAUTO_PART) {
      this.requestId = this.currentUser.partnerId;
    }
    else if (this.currentUser.roleId == RoleEnum.SENDER || this.currentUser.roleId == RoleEnum.PRODAUTO_SENDER) {
      this.requestId = this.currentUser.clientId;
    }
  }

  ngOnInit() {

    var date = new Date();
    date.setFullYear(new Date().getFullYear() + 10);

    this.yearRange = (new Date().getFullYear() - 1) + ":" + date.getFullYear();

    this.fr = {
      firstDayOfWeek: 1,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      monthNames: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec"],
      today: "Aujourd'hui",
      clear: 'Clear',
      dateFormat: 'dd/MM/yy',
      weekHeader: 'Wk'
    };

    this.spoolColumns = [
      { field: 'clientCode', header: 'Code client' },
      { field: 'applicationCode', header: 'Code application' },
      { field: 'pacName', header: 'Emetteur' },
      { field: 'numFdr', header: 'Fdr' },
      { field: 'depositDate', header: 'Date de dépôt' },
      { field: 'lastEventDate', header: 'Date dernier évènement' },
      { field: 'zipName', header: 'nom zip' },
      { field: 'nbDocs', header: 'Nb docs' },
      { field: 'totalWeight', header: 'Poids total' }
    ];
  }


  loadSpoolLazy(event: LazyLoadEvent) {
    this.loading = true;
    this.event = event;

    let filter = {
      first: event.first,
      rows: event.rows,
      partnerCode: this.currentUser.partnerLabel,
      pid: this.currentUser.clientLabel,
      roleId: this.currentUser.roleId,
      clientCode: event.filters != null && event.filters.clientCode != null ? event.filters.clientCode.value : null,
      applicationCode: event.filters != null && event.filters.applicationCode != null ? event.filters.applicationCode.value : null,
      pacName: event.filters != null && event.filters.pacName != null ? event.filters.pacName.value : null,
      numFdr: event.filters != null && event.filters.numFdr != null ? event.filters.numFdr.value : null,
      depositDate: event.filters != null && event.filters.depositDate != null ? event.filters.depositDate.value : null,
      lastEventDate: event.filters != null && event.filters.lastEventDate != null ? event.filters.lastEventDate.value : null,
      zipName: event.filters != null && event.filters.zipName != null ? event.filters.zipName.value : null,
      nbDocs: event.filters != null && event.filters.nbDocs != null ? event.filters.nbDocs.value : null,
      totalWeight: event.filters != null && event.filters.totalWeight != null ? event.filters.totalWeight.value : null,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };

    this.rabbitMqService.getSpool(filter).subscribe(spools => {
      this.totalRecords = spools.count;
      this.spoolCollection = spools.spoolList;

      this.loading = false;
    });

    setTimeout(() => {
      
    }, 1000);
  }


  showSpoolHistory(selectedSpool) {

    this.detailLoading = true;

    const ref = this.dialogService.open(SpoolDetail, {
      data: {
        // Passing data to SpoolDetail component
        selectedStatusCode: selectedSpool,
        selectedSpool: selectedSpool,
        detailLoading: this.detailLoading,
        numFdr: selectedSpool.numFdr
      },
      header: 'Numéro de FDR: ' + selectedSpool.numFdr,
      width: '90%',
      height: '90%',
      contentStyle: { "height": "90%", "max-height": "95%", "overflow": "auto" }
    });
  }
}
