import { MessageService, DialogService } from 'primeng/api';
import { Component, ViewEncapsulation } from '@angular/core';
import { FlowTypeModel } from '../../models/FlowTypeModel';
import { FlowTypeEnum } from 'src/app/models/FlowTypeEnum';
import { FlowTrackingService } from '../../Services/flowTracking.service';


@Component({
  templateUrl: './flowTrackingConfig.component.html',
  styleUrls: ['./flowTrackingConfig.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, MessageService]
})
export class FlowTrackingConfig {

  loading = true;
  displayDialog: boolean;
  flowTypeCollection: any[];
  columns: any[];
  types: any[];
  newFlowType: boolean;
  flowType: FlowTypeModel;
  selectedFlow: FlowTypeModel;
  totalRecords: number;

  constructor(private flowTrackingService: FlowTrackingService, private messageService: MessageService) {

  }

  ngOnInit() {

    this.columns = [
      { field: 'clientCode', header: 'code client' },
      { field: 'applicationCode', header: 'code application' },
      { field: 'flowType', header: 'Type de flux' }
    ];

    this.flowTrackingService.getFlowTypes().subscribe(flowTypes => {
      this.loading = false;
      this.flowTypeCollection = flowTypes;

      if (this.flowTypeCollection != null) {
        this.totalRecords = this.flowTypeCollection.length;

        this.flowTypeCollection.forEach(x => {
          x.flowType = FlowTypeEnum[x.flowType];
        }
        );
      }
      

    });

    this.types = [
      {
        value: 'PREINSCRIPTION',
        label: "PREINSCRIPTION"
      },
      {
        value: 'SPOOL',
        label: "SPOOL"
      }];
    
    this.types.unshift({
      value: null,
      label: null
    });


  }

  onRowSelect(event) {
    this.newFlowType = false;
    this.flowType = this.cloneItem(event.data);
    this.displayDialog = true;
  }

  showDialogToAdd() {
    this.newFlowType = true;
    this.flowType = new FlowTypeModel();
    this.displayDialog = true;
  }

  cloneItem(c: FlowTypeModel): FlowTypeModel {
    let a = new FlowTypeModel();
    for (let prop in c) {
      a[prop] = c[prop];
    }
    return a;
  }

  save() {
    let flowTypes = this.flowTypeCollection != null ? [...this.flowTypeCollection] : [];

    if (this.newFlowType) {
      this.flowTrackingService.addFlowType(this.flowType).subscribe(result => {
        if (result) {
          flowTypes.push(this.flowType);

          this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le type de flux a été ajouté avec succès", life: 4000 });

          this.flowTypeCollection = flowTypes;
          this.flowType = null;
          this.displayDialog = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "le couple code client/code appli existe déjà.", life: 4000 });
        }
      });
    }
    else {
      this.flowTrackingService.editFlowType(this.flowType).subscribe(result => {
        if (result) {
          flowTypes[this.flowTypeCollection.indexOf(this.selectedFlow)] = this.flowType;

          this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le type de flux a été modifié avec succès", life: 4000 });

          this.flowTypeCollection = flowTypes;
          this.flowType = null;
          this.displayDialog = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "le couple code client/code appli existe déjà.", life: 4000 });
        }
      });
    }
    
  }

  delete() {

    this.flowTrackingService.deleteFlowType(this.selectedFlow).subscribe(result => {
      if (result) {
        this.messageService.add({ severity: 'success', summary: 'Information', detail: "Le type de flux a été supprimé avec succès", life: 4000 });

        let index = this.flowTypeCollection.indexOf(this.selectedFlow);
        this.flowTypeCollection = this.flowTypeCollection.filter((val, i) => i != index);
        this.flowType = null;
        this.displayDialog = false;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Une erreur est survenue lors de la suppression. Veuillez réessayer plus tard.", life: 4000 });
      }
    });
  }

}
