export enum RoleEnum {
  ADMIN = 1,
  EXPLOIT = 2,
  PART = 3,
  PRODAUTO_PART = 4,
  PRODAUTO_SENDER = 5,
  RC = 6,
  SENDER = 7,
  
}
