import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SpoolModel } from '../models/SpoolModel';
import { ReferentielModel } from '../models/ReferentielModel';
import { FlowTypeModel } from '../models/FlowTypeModel';
import { SpoolModelLazy } from '../models/SpoolModelLazy';
import { ReferentielModelLazy } from '../models/referentielModelLazy';

@Injectable()
export class FlowTrackingService {

  url: string;

  constructor(
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl;
  }

  getAllReferentiel(filter: any) {
    return this.http.post<ReferentielModelLazy>(this.url + 'api/flowTracking/GetReferentiel/', filter);
  }

  getRefHistory(fdr: number) {
    return this.http.get<ReferentielModel[]>(this.url + 'api/flowTracking/GetRefHistory/' + fdr);
  }

  getSpool(filter: any) {
    return this.http.post<SpoolModelLazy>(this.url + 'api/flowTracking/GetSpool/', filter);
  }

  getSpoolHistory(fdr: number) {
    return this.http.get<SpoolModel[]>(this.url + 'api/flowTracking/GetSpoolHistory/' + fdr);
  }

  getFlowTypes() {
    return this.http.get<FlowTypeModel[]>(this.url + 'api/flowTracking/GetFlowTypes/');
  }

  addFlowType(flowType: FlowTypeModel) {
    return this.http.post<any>(this.url + 'api/flowTracking/AddFlowType/', flowType);
  }

  editFlowType(flowType: FlowTypeModel) {
    return this.http.post<any>(this.url + 'api/flowTracking/EditFlowType/', flowType);
  }

  deleteFlowType(flowType: FlowTypeModel) {
    return this.http.post<any>(this.url + 'api/flowTracking/DeleteFlowType/', flowType);
  }
}
