import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Router } from '@angular/router';
import { UserToAdd } from '../models/UserToAdd';
import { PartnerModel } from '../models/PartnerModel';
import { SenderModel } from '../models/SenderModel';

@Injectable()
export class UserService {

  url: string;

  constructor(
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl;
  }

  addUser(userToAdd: UserToAdd) {
    return this.http.post<any>(this.url + 'api/user/AddUser', userToAdd);
  }

  addWsUser(userToAdd: UserToAdd) {
    return this.http.post<any>(this.url + 'api/user/AddWsUser', userToAdd);
  }

  updateUser(userToAdd: UserToAdd) {
    return this.http.put<any>(this.url + 'api/user/UpdateIhmUser', userToAdd);
  }

  updateWsUser(userToAdd: UserToAdd) {
    return this.http.put<any>(this.url + 'api/user/UpdateWsUser', userToAdd);
  }

  deleteUser(userId: number) {
    return this.http.delete<any>(this.url + 'api/user/DeleteIhmUser?userId=' + userId);
  }

  deleteWsUser(userId: number) {
    return this.http.delete<any>(this.url + 'api/user/DeleteWsUser?userId=' + userId);
  }

  getAllIhmUsers() {
    return this.http.get<any>(this.url + 'api/user/GetIhmUsers');
  }

  getAllWsUsers() {
    return this.http.get<any>(this.url + 'api/user/GetWsUsers');
  }

  getAllWsRoles() {
    return this.http.get<any>(this.url + 'api/user/GetWsRoles');
  }

  getAllRoles() {
    return this.http.get<any>(this.url + 'api/user/GetRoles');
  }

  getAllPartners() {
    return this.http.get<any>(this.url + 'api/user/GetPartners');
  }

  getAllSenders() {
    return this.http.get<any>(this.url + 'api/user/GetSenders');
  }

  getAllWsSenders() {
    return this.http.get<any>(this.url + 'api/user/GetWsSenders');
  }

  getAllProdAutoPartners() {
    return this.http.get<any>(this.url + 'api/user/GetProdAutoPartners');
  }
  
  addProdAutoPartner(partner: PartnerModel) {
    return this.http.post<any>(this.url + 'api/user/AddProdAutoPartner/', partner);
  }

  editProdAutoPartner(partner: PartnerModel) {
    return this.http.post<any>(this.url + 'api/user/EditProdAutoPartner/', partner);
  }

  deleteProdAutoPartner(partner: PartnerModel) {
    return this.http.post<any>(this.url + 'api/user/DeleteProdAutoPartner/', partner);
  }

  getAllProdAutoSenders() {
    return this.http.get<any>(this.url + 'api/user/GetProdAutoSenders');
  }

  addProdAutoSender(sender: SenderModel) {
    return this.http.post<any>(this.url + 'api/user/AddProdAutoSender/', sender);
  }

  editProdAutoSender(sender: SenderModel) {
    return this.http.post<any>(this.url + 'api/user/EditProdAutoSender/', sender);
  }

  deleteProdAutoSender(sender: SenderModel) {
    return this.http.post<any>(this.url + 'api/user/DeleteProdAutoSender/', sender);
  }

}
