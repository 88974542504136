export class NewPartner {
  code: string;
  label: string;
  dolly: string;
  certificate: File;
  certificate_password: string;

  isOkoro: boolean;
  okoroUrl: string;
  okoroLogin: string;
  okoroPassword: string;
  okoroCreationSerie: string;
  okoroDepositSerie: string;
  okoroOrganizationCode: string;
  okoroOrganizationDepth: string;
  okoroActivityCode: string;
  okoroActivityDepth: string;
  okoroActivity2Code: string;
  okoroActivity2Depth: string;
  okoroUserProfil: string;
  okoroAdminProfil: string;

  isMaileva: string;
  mailevaLogin: string;
  mailevaPassword: string;
  mailevaSftpIdConfig: string;
  mailevaSempPath: string;
  mailevaNotificationFolder: string;
  mailevaNotificationLogin: string;
  docaposteClientCode: string;
  mailevaSftpSempUrl: string;
}
