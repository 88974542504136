import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { SenderModel } from '../models/SenderModel';
import { FacturationModel } from '../models/FacturationModel';
import { concat } from 'rxjs/operators';

@Injectable()
export class FacturationService {

  url: string;
  //public PackageApiServerLink: string;

  constructor(
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }

  getSenders(partnerId: string) {
    return this.http.get<SenderModel[]>(this.url + 'api/facturation/GetSenders/' + partnerId);
  }

  getFacturation(pid: string[], dateFrom: string, dateTo: string) {
    let fact = new FacturationModel();
    fact.pidList = pid;
    fact.dateFrom = dateFrom;
    fact.dateTo = dateTo;

    return this.http.post<string>(this.url + 'api/facturation/GetFacturation/', fact);
  }

  calculateFacturation(date: string) {
    return this.http.get<boolean>(this.url + 'api/Facturation/CalculateFacturation/' + date);
  }
}
