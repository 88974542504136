import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SenderModel } from '../models/SenderModel';
import { RecipientModel } from '../models/RecipientModel';
import { RecipientDataModel } from '../models/RecipientDataModel';
import { RecipientModelLazy } from '../models/RecipientModelLazy';
import { RecipientStatus } from '../models/RecipientStatus';
import { PartnerModel } from '../models/PartnerModel';
import { NewPartner } from '../models/NewPartner';

@Injectable()
export class ReferentielService {

  url: string;

  constructor(
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl;
  }

  getSenders(partnerId: string) {
    return this.http.get<SenderModel[]>(this.url + 'api/facturation/GetSenders/' + partnerId);
  }

  getAllReferentiel(filter: any) {
    return this.http.post<RecipientModelLazy>(this.url + 'api/referentiel/GetReferentiel/', filter);
  }

  getReferentielCsv(pid: string) {
    return this.http.get<string>(this.url + 'api/referentiel/GetReferentielCsv/' + pid);
  }

  getRecipientByClientId(clientId: string, pid: string) {
    return this.http.get<RecipientDataModel>(this.url + 'api/referentiel/GetRecipientByClientId/' + clientId + '/' + pid);
  }

  getRecipientStatus() {
    return this.http.get<RecipientStatus[]>(this.url + 'api/referentiel/GetRecipientStatus/');
  }

  getAllPartners() {
    return this.http.get<PartnerModel[]>(this.url + 'api/referentiel/GetAllPartners/');
  }

  addNewPartner(partner: NewPartner) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
      })
    };

    const formData = new FormData();
    formData.append('certificate', partner.certificate, partner.certificate.name);
    formData.append('code', partner.code);
    formData.append('label', partner.label);
    formData.append('certificate_password', partner.certificate_password);
    formData.append('dolly', partner.dolly);
    formData.append('isOkoro', partner.isOkoro.toString());
    formData.append('okoroUrl', partner.okoroUrl);
    formData.append('okoroLogin', partner.okoroLogin);
    formData.append('okoroPassword', partner.okoroPassword);
    formData.append('okoroCreationSerie', partner.okoroCreationSerie);
    formData.append('okoroDepositSerie', partner.okoroDepositSerie);
    formData.append('okoroOrganizationCode', partner.okoroOrganizationCode);
    formData.append('okoroOrganizationDepth', partner.okoroOrganizationDepth);
    formData.append('okoroActivityCode', partner.okoroActivityCode);
    formData.append('okoroActivityDepth', partner.okoroActivityDepth);
    formData.append('okoroActivity2Code', partner.okoroActivity2Code);
    formData.append('okoroActivity2Depth', partner.okoroActivity2Depth);
    formData.append('okoroUserProfil', partner.okoroUserProfil);
    formData.append('okoroAdminProfil', partner.okoroAdminProfil);

    formData.append('isMaileva', partner.isMaileva);
    formData.append('mailevaLogin', partner.mailevaLogin);
    formData.append('mailevaPassword', partner.mailevaPassword);
    formData.append('mailevaSftpIdConfig', partner.mailevaSftpIdConfig);
    formData.append('mailevaSempPath', partner.mailevaSempPath);
    formData.append('mailevaNotificationFolder', partner.mailevaNotificationFolder);
    formData.append('mailevaNotificationLogin', partner.mailevaNotificationLogin);
    formData.append('docaposteClientCode', partner.docaposteClientCode);
    formData.append('mailevaSftpSempUrl', partner.mailevaSftpSempUrl);


    return this.http.post<any>(this.url + 'api/referentiel/AddPartner/', formData);
  }

}
